import React from 'react'
import '../../../styles/Landing/ExperienceSection.scss'
import CountUp from 'react-countup';
function ExperienceSection() {
  return (
    <div className='bg-secondary px-4 md:px-12 mt-20 sm:mt-32 md:mt-24  xl:mt-36 lg:mt-20 experienceSectionContainer'>
        <div className="grid grid-cols-12 py-10 lg:py-16">
            <div className='col-span-4 md:col-span-6 lg:col-span-6 xl:col-span-6 text-center'>
                    <h3 className='text-primary font-montserrat font-semibold text-lg sm:text-2xl lg:text-5xl md:text-4xl'>OUR EXPERIENCE</h3>
            </div>
            <div className='col-span-4 md:col-span-3 lg:col-span-3 xl:col-span-3 -mt-1 lg:-mt-4 text-center md:text-left'>
                    <h3 className='text-primary font-inter font-semibold lg:text-5xl md:text-4xl text-xl sm:text-2xl'><CountUp enableScrollSpy end={180} duration={2.5}/>+</h3>
                    <p className="text-primary text-sm sm:text-base md:text-lg font-light font-montserrat mt-1">Projects completed</p>
            </div>
            <div className='col-span-4 md:col-span-3 lg:col-span-3 xl:col-span-3 -mt-1 lg:-mt-4 text-center md:text-left'>
                    <h3 className='text-primary font-inter font-semibold lg:text-5xl md:text-4xl text-xl sm:text-2xl'><CountUp enableScrollSpy end={10} duration={2.5}/>+</h3>
                    <p className="text-primary text-sm sm:text-base md:text-lg font-light font-montserrat mt-1">Cities</p>
            </div>
        </div>
    </div>
  )
}

export default ExperienceSection