import React from 'react'

import LandingFooter from '../Landing/components/LandingFooter'
import LandingNavbar from '../Landing/components/LandingNavbar'
import {BuildingBrickSection, OurApproachSection, OurTeamSection, WhoAreWeSection} from './index'
function AboutUs() {
  return (
    <div className='bg-primary'>
      
      <LandingNavbar val={true}/>
      <BuildingBrickSection/>
      <WhoAreWeSection/>
      <OurApproachSection/>
      <OurTeamSection/>
      <LandingFooter back="bg-black"/>
    </div>
  )
}

export default AboutUs