import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import mjb_white from '../../../assets/mjb_light.png'
import mjb_dark from '../../../assets/mjb_dark.png'
// import mjbwhite from '../../../assets/MJBWhite.png'

import '../../../styles/Landing/LandingNavbar.scss'
import { BsThreeDots } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
export default function LandingNavbar({ val, setHide, hide }) {
  const [navbar, setNavbar] = useState(false);
  const [preloader, setPreloader] = useState(false)
  const location = useLocation()
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);
    if (screenSize.dynamicWidth > 768) {
      setNavbar(false)
    }
    return (() => {
      window.removeEventListener('resize', setDimension);
    })

  }, [screenSize])


  useEffect(() => {
    if (preloader || navbar) {
      document.body.style.overflow = "hidden"
    }
    else {
      document.body.style.overflow = "auto"

    }
  }, [preloader, navbar])
  return (
    <div className="flex items-center px-4 md:px-12 navbar">
      <a href="/" className="md:hidden mt-4">
        <img src={val && !navbar ? mjb_white : mjb_dark} alt="mjb" loading="lazy" className="landingNavbarLogo" />
      </a>

      <nav className={`navbar w-full z-10 ${navbar ? "bg-secondary" : "bg-transparent"} ${navbar ? "landingNavbarContainerNew" : "landingNavbarContainer"}`}>
        <div className="justify-between px-4 h-full mx-auto md:items-center md:flex z-50">
          <div>
            <div className="flex items-center justify-between py-3 md:py-8 md:block ">
              <a href="/" className="">
                <img src={val && !navbar ? mjb_white : mjb_dark} alt="mjb" loading="lazy" className="landingNavbarLogo" />
              </a>

              <div className="md:hidden">
                <button
                  className="p-2 text-black rounded-md outline-none"
                  onClick={() => {
                    if (location.pathname !== '/' & location.pathname !== "/about-us" & location.pathname !== "/career" & location.pathname !== "/contact-us" & location.pathname !== "/admin-auth" & location.pathname !== "/admin-projects") {
                      setHide(!hide)
                    }
                    setNavbar(!navbar)
                  }}

                >
                  {navbar ? (
                    <div className=" cursor-pointer">
                      <MdClose color="white" size={30} onClick={() => {
                        setPreloader(false)
                      }} />
                    </div>
                  ) : (
                    <BsThreeDots className={`landingNavbarDots ${val ? "text-black" : "text-white"}`} size={30} />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${navbar ? "block" : "hidden"
                }`}
            >
              <ul className={`items-center flex flex-col justify-center space-y-8 py-3 ${location.pathname === '/' ? "md:flex" : "md:hidden"} md:flex-row md:space-x-6 md:space-y-0 navbarlinks`}>

                <li><a href="/" className="navigatingLink text-primary md:text-secondary font-semibold md:font-medium text-lg font-montserrat hover:underline">Home</a></li>


                <li><a href="/projects" className="navigatingLink text-primary md:text-secondary font-semibold md:font-medium text-lg font-montserrat hover:underline">Projects</a></li>
                <li><a href="/about-us" className="navigatingLink text-primary md:text-secondary font-semibold md:font-medium text-lg font-montserrat hover:underline">About Us</a></li>
                <li><a href="/career" className="navigatingLink text-primary md:text-secondary font-semibold md:font-medium text-lg font-montserrat hover:underline">Career</a></li>
                <li><a href="/contact-us" className="navigatingLink text-primary md:text-secondary font-semibold md:font-medium text-lg font-montserrat hover:underline">Contact Us</a></li>

              </ul>
            </div>
          </div>
          <div className="hidden md:block">
            {
              preloader ? (
                <MdClose color="white" size={30} onClick={() => {
                  if (location.pathname !== '/' & location.pathname !== "/about-us" & location.pathname !== "/career" & location.pathname !== "/contact-us" & location.pathname !== "/admin-auth" & location.pathname !== "/admin-projects") {
                    setHide(false)
                  }
                  setPreloader(false)
                }} />
              )
                :
                (
                  <button onClick={() => {
                    if (location.pathname !== '/' & location.pathname !== "/about-us" & location.pathname !== "/career" & location.pathname !== "/contact-us" & location.pathname !== "/admin-auth" & location.pathname !== "/admin-projects") {
                      setHide(true)
                    }
                    setPreloader(true)
                  }}>
                    <BsThreeDots size={50} className={` ${val ? "text-black" : "text-white"}`} /></button>
                )
            }

          </div>
        </div>

      </nav>
      {
        preloader && (
          <div className="hidden md:block duration-300 ">
            <div className="fixed inset-0 bg-secondary bg-opacity-100 transition-opacity landingNavbarOpacityBack z-10"></div>
            <div className="fixed inset-0 z-10 overflow-hidden bg-inherit overscroll-contain">
              <div className=" px-8 md:px-12 py-3 md:py-8">
                <div className="flex justify-between items-center ">
                  {/* <a href="/">
                    <img src={mjb_dark} alt="mjb" loading="lazy" className="landingNavbarLogo lfe" />
                  </a> */}
                  <div className=" cursor-pointer">
                    <MdClose color="white" size={30} onClick={() => {
                      if (location.pathname !== '/' & location.pathname !== "/about-us" & location.pathname !== "/career" & location.pathname !== "/contact-us" & location.pathname !== "/admin-auth" & location.pathname !== "/admin-projects") {
                        setHide(false)
                      }
                      setPreloader(false)
                    }} />
                  </div>
                </div>
                <div className=" absolute z-50 bottom-20 left-12">
                  <div className=" flex flex-col navbarlinksss">


                    <a href="/" className={` font-montserrat text-primary md:text-6xl lg:text-7xl duration-300 font-semibold mt-6 hover:underline`}>HOME</a>

                    <a href="/projects" className={` font-montserrat text-primary md:text-6xl lg:text-7xl font-semibold mt-6 hover:underline`}>PROJECTS</a>
                    <a href="/about-us" className={`font-montserrat text-primary md:text-6xl lg:text-7xl font-semibold mt-6 hover:underline`}>ABOUT US</a>
                    <a href="/career" className={` font-montserrat text-primary md:text-6xl lg:text-7xl font-semibold mt-6 hover:underline`}>CAREER</a>
                    <a href="/contact-us" className={` font-montserrat text-primary md:text-6xl lg:text-7xl font-semibold mt-6 hover:underline`}>CONTACT US</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }


    </div>

  );
}