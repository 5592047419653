import React from 'react'
import { LandingNavbar } from '../Landing'
import LandingFooter from '../Landing/components/LandingFooter'
import {DetailsSection, FormSection} from './index'
function ContactUs() {
  return (
    <div className='bg-secondary'>
      <LandingNavbar val={false}/>
      <DetailsSection/>
      <FormSection/>
      <LandingFooter back="bg-black"/>
    </div>
  )
}

export default ContactUs