import {ADMIN_LOGIN,CAREER_DATA_UPLOAD,CONTACT_DATA_UPLOAD, FETCH_CAREER_DATA, FETCH_PROJECT_DATA, FETCH_TEAM_DATA, PROJECT_DATA_UPLOAD} from '../constants/index'


const initState={
   userData:null,
   contactData:null,
   careerData:null,
   projectData:null,
   fetchedCareerData:null,
   fetchedTeamData:null,
   fetchedProjectData:null
}

export const commonReducer=(state=initState,action)=>{
    switch(action.type){
  
        case ADMIN_LOGIN:{
            return Object.assign({},state,{
                userData:action.payload,
          
              })
        }
        case CAREER_DATA_UPLOAD:{
            return Object.assign({},state,{
                careerData:action.payload,
          
              })
        }
        case CONTACT_DATA_UPLOAD:{
            return Object.assign({},state,{
                contactData:action.payload,
          
              })
        }
        case PROJECT_DATA_UPLOAD:{
            return Object.assign({},state,{
                projectData:action.payload,
          
              })
        }
        case FETCH_CAREER_DATA:{
            return Object.assign({},state,{
                fetchedCareerData:action.payload,
          
              })
        }
        case FETCH_TEAM_DATA:{
            return Object.assign({},state,{
                fetchedTeamData:action.payload,
          
              })
        }
        case FETCH_PROJECT_DATA:{
            return Object.assign({},state,{
                fetchedProjectData:action.payload,
          
              })
        }
        default:
            return state
    }
}