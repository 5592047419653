import React, { useState } from 'react'
import call from '../../../../assets/call.png'
import '../../../../styles/Landing/ContactOptions.scss'
import {IoMdCall} from 'react-icons/io'
import {RiWhatsappLine} from 'react-icons/ri'
import {HiOutlineMailOpen} from 'react-icons/hi'
function ContactOptions() {
   const [options,setOptions]=useState(false) 

    return (
        <div className='sticky contactdetailszindex'>
            <div className={`fixed top-20 right-5 md:top-40 md:right-12 `}>
                <div onClick={(e) => setOptions(!options)} className=" bg-black w-12 h-12 duration-300  md:w-16 md:h-16 rounded-full relative contactOptionsBox">
                    <div className="flex justify-center">
                        <img src={call} alt="call" className='mt-2 p-1 md:mt-3' />
                    </div>
                    {options ? <div className="contactOption1 bg-black w-8 h-8 text-center duration-500 absolute  text-white rounded-full"><a href="mailto:contact@mjbarchitects.in"><HiOutlineMailOpen className='ml-2 mt-2'/></a></div>: null}
                    {options ? <div className="contactOption2 bg-black w-8 h-8 text-center duration-500  absolute  text-white rounded-full"><a href="https://wa.me/919898576357"><RiWhatsappLine className='ml-2 mt-2'/></a></div>: null}
                    {options ? <div className="contactOption3 bg-black w-8 h-8 text-center duration-500  absolute  text-white rounded-full"><a href="tel:+919898576357"><IoMdCall className='ml-2 mt-2'/></a></div>: null}
                </div>
            </div>
        </div>
    )
}

export default ContactOptions