import React from 'react'
import {IoCallSharp} from 'react-icons/io5'
import {BsWhatsapp} from 'react-icons/bs'
import detailsmap from '../../../assets/detailsmap.png'
import '../../../styles/ContactUs/DetailsSection.scss'
function DetailsSection() {
  return (
    <div className='px-4 sm:px-14 md:px-16 lg:px-32 mt-10'>
          <div className="grid grid-cols-12 py-10 lg:py-16">
          <div className='col-span-12'>
                <h2 className='text-primary font-montserrat text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-semibold tracking-wide'>Say hello.</h2>
          </div>
          <div className='col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5 xl:col-span-4 mt-10 md:flex lg:block lg:flex-col md:items-center md:justify-between'>
            <div>
            <h4 className='font-montserrat text-xl sm:text-2xl font-semibold text-secondarygray'>Address</h4>
            <p className='text-white font-montserrat font-light text-sm sm:text-base'>205 MJB Architects, Rivera Wave complex, near RPJ Hotel, Kalawad Road, Rajkot, Gujarat 360005</p>
            <div className="mt-8">
                <button className='border border-white px-8 py-2.5 text-xs sm:text-sm font-montserrat font-semibold text-primary hover:border-secondarygray duration-300'>
                  <a href="https://www.google.com/maps/place/MJB+Architects/@22.2730401,70.7543687,17z/data=!3m1!4b1!4m6!3m5!1s0x3959cb5510f53981:0xbc3ad27c353de658!8m2!3d22.2730401!4d70.7569436!16s%2Fg%2F11h4675scx?authuser=0&entry=ttu" rel="noreferrer" target="_blank">Get Directions</a>
                </button>
            </div>
            </div>
            <div className='mt-8 md:mt-7 lg:mt-36 xl:mt-40'>
            <h4 className='font-montserrat text-xl sm:text-2xl font-semibold text-secondarygray'>Need to reach out ?</h4>
            <div className="flex items-center md:block">
            <a href="tel:+919898576357">
              <button className='border border-white hover:border-secondarygray duration-300 px-14 py-2.5 text-xs sm:text-sm font-montserrat font-semibold text-primary mt-3 flex items-center'>Call Us <IoCallSharp className='ml-1 -mt-1'/>
              </button>
            </a>
            <a href="https://wa.me/919898576357">
              <button className='border border-white hover:border-secondarygray duration-300 px-8 py-2.5 text-xs sm:text-sm font-montserrat font-semibold text-primary mt-3 ml-3 md:ml-0 lg:mt-6 flex items-center'>
                WhatsApp Us <BsWhatsapp className='ml-2 -mt-1'/>
              </button>
              </a>
            </div>
            </div>
          </div>
          <div className='col-span-12 md:col-span-12 lg:col-span-7 xl:col-span-8 mt-10 md:mt-14 lg:mt-10'>
            <div className="flex justify-center">
            <img src={detailsmap} alt="detailsmap" className='detailsSectionImg' />
            </div>
           
          </div>
          </div>
    </div>
  )
}

export default DetailsSection