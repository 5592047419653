import React, { useEffect, useState } from 'react'
import '../../../styles/Projects/AllProjectsSection.scss'
import { ProjectsFilter } from '../index'
import filter from '../../../assets/filter.png'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectsData } from '../../../redux/actions'
import ScaleLoader from 'react-spinners/ScaleLoader'
function AllProjectsSection({ hide }) {
    const dispatch = useDispatch()
    const fetchedProjectData = useSelector(state => state.fetchedProjectData)

    const [showFilter, setShowFilter] = useState(false)
    const [filterOption, setFilterOption] = useState("")
    const [typeName, setTypeName] = useState("ALL PROJECTS")

    useEffect(() => {
        dispatch(getProjectsData())
    }, [dispatch])

    return (
        <div className='px-4 sm:px-14 md:px-16 lg:px-40 min-h-full'>
            <div className="grid grid-cols-12 py-10 lg:py-12">
                <ProjectsFilter filterOption={filterOption} showFilter={showFilter} setShowFilter={setShowFilter} setFilterOption={setFilterOption} setTypeName={setTypeName} />
                <div className="col-span-12 inlinealign">
                    <h2 className='font-montserrat leading-snug text-secondary text-2xl sm:text-4xl md:text-5xl lg:text-7xl font-semibold uppercase'>{typeName}</h2>
                    <div className="flex justify-end">
                        <img src={filter} onClick={() => setShowFilter(true)} alt="filter" className='cursor-pointer w-10 sm:w-auto' />
                    </div>
                </div>
            </div>

            <div className={`grid grid-cols-12 py-6 lg:py-4 gap-8`}>
                {
                    fetchedProjectData ? (
                        <>
                            {
                                fetchedProjectData.filter(x => filterOption === "" ? x.type !== "" : x.type === filterOption).map(ot => (
                                    <a href={`/projects/${ot.id}/${ot.name}`} key={ot.id} className={`relative ${hide ? "-z-10" : "z-0"} col-span-12 sm:col-span-12  md:col-span-6 lg:col-span-6 xl:col-span-6 md:mt-5 lg:mt-4 xl:mt-10`}>
                                        <div className='allProjectsSectionOpacityContainer'>
                                            <img onContextMenu={e => e.preventDefault()} src={ot.url} alt={ot.name} className={`rounded-md allProjectsSectionImg `} />
                                            <div className='allProjectsSectionOpacityBox'>
                                                <div className='absolute top-0 left-0  flex justify-between flex-col w-full '>
                                                    <h3 className="font-montserrat p-2  text-lg sm:text-xl md:text-2xl lg:text-4xl xl:text-5xl text-white font-semibold  bg-gradient-to-t from-transparent to-zinc-600">{ot.name}</h3>
                                                    <p className="font-montserrat flex items-center text-xs sm:text-lg lg:text-lg xl:text-lg text-white ml-3 allProjectsSectionAddressLine">{ot.location}</p>
                                                </div>
                                                <div className='absolute bottom-0 right-0 float-right p-2 flex justify-between flex-col w-full bg-gradient-to-b from-transparent to-zinc-600'>
                                                    <p className="font-montserrat flex items-center justify-end text-xs sm:text-base font-semibold text-white  allProjectsSectionAddressLine">view more</p>
                                                </div>
                                            </div>


                                        </div>
                                    </a>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            <div className="col-span-12 my-40">
                                <div className='flex justify-center items-center'>
                                    <ScaleLoader size={100} color="black" />

                                </div>
                            </div>
                        </>
                    )

                }

            </div>




        </div>
    )
}

export default AllProjectsSection