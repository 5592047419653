import React, { useEffect, useState } from 'react'
import '../../../styles/AboutUs/OurTeamSection.scss'
import OurTeamSectionModal from './helper/OurTeamSectionModal'
import { getTeamData } from '../../../redux/actions'

function OurTeamSection() {
    const [showModal, setShowModal] = useState(false)
    const [id, setId] = useState(0)
    const [item, setItem] = useState({})
    const [ourTeamData, setTeamData] = useState([])
    const handleModal = (item) => {
        setShowModal(true)
        setId(item?.id)
        setItem(item)
    }
    const modal = () => {
        return (
            <OurTeamSectionModal id={id} setShowModal={setShowModal} item={item} />
        )
    }

    useEffect(() => {
        getTeamData()
        .then(res => setTeamData(res))
        .catch(err => {
        })
    }, [])

    return (
        <div className='px-4 sm:px-14 md:px-16 lg:px-40 mt-10'>
            <div className="grid grid-cols-12 py-10 lg:py-16 gap-3 lg:gap-10">
                <div className="col-span-12">
                    <h2 className='font-montserrat leading-snug text-secondary text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold'>OUR TEAM</h2>
                </div>
                <div className="col-span-12 ">
                    {
                        showModal && (
                            modal()

                        )
                    }
                </div>
                {
                    ourTeamData.map(ot => (
                        <>
                            <div onClick={() => handleModal(ot)} key={ot.id} className={`relative col-span-6 sm:col-span-6  md:col-span-6 lg:col-span-6 xl:col-span-6 md:mt-5 lg:mt-4 xl:mt-10`}>
                                <div className='ourTeamSectionOpacityContainer'>
                                    <img src={ot.image} alt={ot.fullName} className={`rounded-md outTeamSectionImg w-full`} />
                                    <div className='ourTeamSectionOpacityBox'>
                                        <div className='absolute top-0 left-0 right-0 p-2 flex justify-between flex-col bg-gradient-to-t from-transparent to-zinc-600'>
                                            <p className="font-montserrat text-xs sm:text-lg lg:text-lg xl:text-xl font-semibold  text-white ml-2">{ot.designation}</p>
                                            <p className="font-montserrat text-xs sm:text-lg lg:text-lg xl:text-xl font-semibold  text-white ml-2">{ot.subrole}</p>

                                        </div>
                                        <div className='absolute bottom-0 right-0 p-2 flex justify-between flex-col w-full bg-gradient-to-b from-transparent to-zinc-600'>
                                            <h3 className="font-montserrat text-end text-lg sm:text-xl md:text-2xl lg:text-4xl xl:text-5xl text-white font-semibold ml-2">{ot.fullName}</h3>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            {/* {
                                ot.id % 2 === 0 && (
                                    <div className="col-span-12 xl:col-span-4"></div>
                                )
                            } */}
                        </>
                    ))
                }
            </div>
        </div>
    )
}

export default OurTeamSection