import React, { useEffect, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../../../styles/Landing/TrustedBySection.scss'
import { getTrustedByImages } from '../../../redux/actions';

function TrustedBySection() {
    const [trustedByImages, setTrustedByImages] = useState([])

    useEffect(() => {
        getTrustedByImages()
        .then(res => setTrustedByImages(res))
        .catch(err => {})
    }, [])

    const getName = url => {
        // if(url) return url.split('trusted-by')[1].split('?')[0].split('-')[1].split('.')[0]
        return ""
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1024 },
            items: 5,
            slidesToSlide: 5

        },
        desktop: {
            breakpoint: { max: 1024, min: 768 },
            items: 5,
            slidesToSlide: 5
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 3,
            slidesToSlide: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 3
        }
    };
    const trustData = trustedByImages.map((tr) => (
        <div className="flex justify-center trustedBySection" key={tr}>
            <img src={tr} alt={getName(tr)} className="mb-10 mt-4 trustedBySectionImg" />
        </div>

    ))
    return (
        <div className='mt-10 sm:mt-20 px-4 md:px-12'>
            <h4 className="text-center text-secondary font-montserrat font-semibold text-xl sm:text-3xl">TRUSTED BY</h4>
            
            <div className="grid grid-cols-12 py-4 lg:py-8 gap-6 mt-1 md:mt-6"
            style={{
                transform: "translateX(0px)"
            }}
            >
                <div className="col-span-12 xl:col-span-1"></div>
                <div className='col-span-12 xl:col-span-10'>
                    <Carousel
                        showDots={true}
                        arrows={false}
                        responsive={responsive}
                        autoPlay={true}
                        infinite={true}
                        autoPlaySpeed={2000}
                        transitionDuration={500}
                        >
                        {trustData}

                    </Carousel>
                </div>
                <div className="col-span-12 xl:col-span-1"></div>
            </div>



        </div>
    )
}

export default TrustedBySection