import React from 'react'
import { LandingNavbar } from '../Landing'
import ContactOptions from '../Landing/components/helper/ContactOptions'
import LandingFooter from '../Landing/components/LandingFooter'
import JoinUsSection from './components/JoinUsSection'
import QuestionsForm from './components/QuestionsForm'

function Career() {
  return (
    <div className='bg-primary'>
      {/* <ContactOptions/> */}
      <LandingNavbar val={true}/>
      <JoinUsSection/>
     
      <QuestionsForm/>
      <LandingFooter back="bg-black"/>
    </div>
  )
}

export default Career