import React from 'react'
import greathomes from '../../../assets/greathomes.png'
import '../../../styles/Landing/GreatHomeSection.scss'
function GreatHomeSection() {
    return (
        <div className='mt-2 sm:mt-10 px-4 md:px-12'>
            <div className="grid grid-cols-12 py-4 lg:py-8 gap-6">
                <div className='col-span-12 xl:col-span-2'></div>
                <div className='col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 block md:hidden'>
                    <div className="flex justify-center">
                        <img src={greathomes} alt="greathome" loading="lazy" className='greatHomeSectionImg' />
                    </div>
                </div>
                <div className='col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4 xl:-ml-10'>
                    <h3 className='text-xl sm:text-3xl md:text-4xl xl:text-5xl font-semibold font-montserrat text-secondary text-center sm:text-left'>Great <span className='text-primarygray'>homes begin with great</span> relationships</h3>
                    <p className="font-montserrat text-center sm:text-left mt-4 sm:mt-10 text-sm sm:text-base">Throughout the design process, we collaborate with each client to gain an understanding of their unique needs – defining the success of each building.</p>
                </div>
                <div className='col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 hidden md:block'>
                    <div className="flex justify-center">
                        <img src={greathomes} alt="greathome" loading="lazy" className='greatHomeSectionImg' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GreatHomeSection