import React from 'react'
import './App.scss'
import { BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import { AboutUs, Career, ContactUs,Landing, Projects } from './globals'
function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' element={<Landing/>}/>
        <Route path='/career' element={<Career/>}/>
        <Route path='/contact-us' element={<ContactUs/>}/>
        <Route path='/projects/*' element={<Projects/>}/>
        <Route path='/about-us' element={<AboutUs/>}/>
      </Routes>
    </Router>
    </>
  )
}

export default App