import React from 'react'
import '../../../styles/AboutUs/OurApproachSection.scss'
function OurApproachSection() {
  return (
    <>
      <div className='px-4 sm:px-14 md:px-16 lg:px-40 mt-10'>
        {/* <div className="grid grid-cols-12 py-10 lg:py-16">
          <div className="col-span-8 md:col-span-6 lg:col-span-7 xl:col-span-4">
            <h2 className='font-montserrat leading-snug text-secondary text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold'>Our Approach</h2>
          </div>
          <div className="col-span-12 md:col-span-8 lg:col-span-5 xl:col-span-8">
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-5 mt-4">
            <p className='text-secondary font-montserrat font-medium mt-2  text-sm md:text-base lg:text-lg'>Anything that matters is founded on solid convictions. These convictions are the core principles that both define and drive our work. They are the cornerstones of everything we do. We are guided by the principles of integrity, compassion, and excellence. These principles guide our actions and our thinking.</p>
          </div>
        </div> */}
        <div className="grid grid-cols-12 py-10 lg:py-16">
          <div className="col-span-8 md:col-span-4 lg:col-span-5 xl:col-span-4">
            <h2 className='font-montserrat leading-snug text-secondary text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold'>Our Approach</h2>
          </div>
          <div className="col-span-12 md:col-span-2 lg:col-span-2 xl:col-span-1">
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-5">
          <p className='text-secondary font-montserrat font-medium mt-2  text-sm md:text-base lg:text-lg'>Anything that matters is founded on solid convictions. These convictions are the core principles that both define and drive our work. They are the cornerstones of everything we do. We are guided by the principles of integrity, compassion, and excellence. These principles guide our actions and thinking.</p>
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-12 lg:py-8 px-4 sm:px-14 md:px-16 lg:px-40">
        <div className='col-span-12 sm:col-span-12  md:col-span-12 lg:col-span-6 xl:col-span-6'></div>
        <div className='ourApproachBox5 col-span-12 hidden md:block sm:col-span-6  md:col-span-6 lg:col-span-3 xl:col-span-3 border border-primarygray px-4 py-8 sm:py-12 xl:py-16 border-r-gray-400'>
          <h5 className=" text-xl sm:text-2xl font-montserrat font-semibold">Adapt to excel</h5>
          <p className=" font-montserrat text-sm mt-2 sm:text-base">Be open to constructive feedback and criticism. Don’t fall blindly in love with an idea. Make sure to always think in alternatives. Chasing an elusive ideal is frustrating. Developing an idea towards the best outcome is liberating.</p>
        </div>
        <div className='ourApproachBox4 col-span-12 hidden md:block sm:col-span-6  md:col-span-6 lg:col-span-3 xl:col-span-3 border border-primarygray px-4 py-8 sm:py-12 xl:py-16 border-l-gray-400'>
          <h5 className=" text-xl sm:text-2xl font-montserrat font-semibold">Don’t get too comfortable</h5>
          <p className=" font-montserrat text-sm mt-2 sm:text-base">Routine is the enemy of creativity. Stay hungry. Keep working as if it’s your first big assignment. And then hit it out of the park.</p>
        </div>


        <div className='col-span-12 mt-10 lg:mt-0 sm:col-span-12 md:col-span-12 lg:col-span-1 xl:col-span-2'></div>
        <div className='ourApproachBox1 col-span-12 mt-auto  sm:col-span-12 md:h-40 lg:h-32 md:col-span-4 lg:col-span-3 xl:col-span-2 border border-primarygray px-4 py-8 border-l-gray-400'>
          <h5 className=" text-xl sm:text-2xl font-montserrat text-center md:text-start font-semibold">Design for communities</h5>
        </div>
        <div className='ourApproachBox2 col-span-12 sm:col-span-6  md:col-span-4 lg:col-span-4 xl:col-span-4 border border-primarygray px-4 py-8 sm:py-10 border-r-gray-400'>
          <h5 className=" text-xl sm:text-2xl font-montserrat font-semibold">Adapt to excel</h5>
          <p className=" font-montserrat text-sm mt-2 sm:text-base">Be open to constructive feedback and criticism. Don’t fall blindly in love with an idea. Make sure to always think in alternatives. Chasing an elusive ideal is frustrating. Developing an idea towards the best outcome is liberating.</p>
        </div>
        <div className='ourApproachBox3 col-span-12 sm:col-span-6  md:col-span-4 lg:col-span-4 xl:col-span-4 border border-primarygray px-4 py-8 sm:py-10 border-l-gray-400'>
          <h5 className=" text-xl sm:text-2xl font-montserrat font-semibold">Don’t get too comfortable</h5>
          <p className=" font-montserrat text-sm mt-2 sm:text-base">Routine is the enemy of creativity. Stay hungry. Keep working as if it’s your first big assignment. And then hit it out of the park.</p>
        </div>
        <div className='ourApproachBox4 col-span-12 block md:hidden sm:col-span-6  md:col-span-6 lg:col-span-3 xl:col-span-3 border border-primarygray px-4 py-8 sm:py-12 xl:py-16 border-r-gray-400'>
          <h5 className=" text-xl sm:text-2xl font-montserrat font-semibold">Adapt to excel</h5>
          <p className=" font-montserrat text-sm mt-2 sm:text-base">Be open to constructive feedback and criticism. Don’t fall blindly in love with an idea. Make sure to always think in alternatives. Chasing an elusive ideal is frustrating. Developing an idea towards the best outcome is liberating.</p>
        </div>
        <div className='ourApproachBox5 col-span-12 block md:hidden sm:col-span-6  md:col-span-6 lg:col-span-3 xl:col-span-3 border border-primarygray px-4 py-8 sm:py-12 xl:py-16 border-l-gray-400'>
          <h5 className=" text-xl sm:text-2xl font-montserrat font-semibold">Don’t get too comfortable</h5>
          <p className=" font-montserrat text-sm mt-2 sm:text-base">Routine is the enemy of creativity. Stay hungry. Keep working as if it’s your first big assignment. And then hit it out of the park.</p>
        </div>
      </div> */}
    </>
  )
}

export default OurApproachSection