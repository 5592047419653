import React, { useEffect, useState } from "react";
import "../../../styles/Landing/AboutUsSection.scss";
import "../../../styles/AboutUs/OurTeamSection.scss";
import { getTeamData } from '../../../redux/actions'
// import owner1 from '../../../assets/owner1.png'
// import owner2 from '../../../assets/owner2.png'

function AboutUsSection() {

    const [teamMembers, setTeamMembers] = useState([])

    useEffect(() => {
        getTeamData()
        .then(res => {
            let end = res?.length > 2 ? 2 : res?.length
            setTeamMembers(res.slice(0, end))
        })
        .catch(err => {
        })
    }, [])




  return (
    <div className="mt-10 px-4 sm:px-14 md:px-16 lg:px-16 xl:px-38">
      <div className="grid grid-cols-12 gap-3 md:gap-10 py-4 lg:py-8 mt-1 md:mt-6">
        <div className="col-span-12 lg:col-span-1"></div>
        {teamMembers && teamMembers.map(ot => 
            <div className="col-span-6 md:col-span-6 lg:col-span-5 xl:col-span-5 relative">
            <div className="ourTeamSectionOpacityContainer">
                <img
                src={ot.image}
                alt={ot.fullName}
                className={`rounded-md outTeamSectionImg w-full`}
                />
                <div className="ourTeamSectionOpacityBox">
                <div className="absolute top-0 left-0 right-0 p-2 flex justify-between flex-col bg-gradient-to-t from-transparent to-zinc-600">
                    <p className="font-montserrat text-xs sm:text-lg lg:text-lg xl:text-xl font-semibold  text-white ml-2">
                    {ot.designation}
                    </p>
                    <p className="font-montserrat text-xs sm:text-lg lg:text-lg xl:text-xl font-semibold  text-white ml-2">
                    {ot.subrole}
                    </p>
                </div>
                <div className="absolute bottom-0 right-0 p-2 flex justify-between flex-col w-full bg-gradient-to-b from-transparent to-zinc-600">
                    <h3 className="font-montserrat text-end text-lg sm:text-xl md:text-2xl lg:text-4xl xl:text-5xl text-white font-semibold ml-2">
                    {ot.fullName}
                    </h3>
                </div>
                </div>
            </div>
            </div>
        )}
        <div className="col-span-12 lg:col-span-1"></div>

        <div className="col-span-12 lg:col-span-1"></div>
        <div className="col-span-12 lg:col-span-8 mt-8">
          <p className="text-secondary font-montserrat lg:text-left text-center text-xl md:text-2xl lg:text-3xl">
            Get to know more
          </p>
          <div className="mt-3 text-center lg:text-left">
            <a
              href="/about-us"
              className="navigatingLink text-secondary font-montserrat font-semibold text-2xl sm:text-3xl md:text-4xl lg:text-6xl"
            >
              ABOUT US
            </a>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-2"></div>
      </div>
    </div>
  );
}

export default AboutUsSection;
