import React, { useEffect, useState } from 'react'
import '../../../../styles/Landing/PreloadScreen.scss'
import preload1 from '../../../../assets/preload1.png'
import preload2 from '../../../../assets/preload2.png'

function PreloadScreen() {
  const [load, setLoad] = useState(false)
  useEffect(() => {
    if (load) {
      document.body.style.overflow = "hidden"
    }
    else {
      document.body.style.overflow = "auto"
    }
    setTimeout(() => {
      setLoad(true)
    }, 10)
  }, [load])
  return (
    <div>
      <div className=" duration-300 ">
        <div className="fixed inset-0 bg-primary bg-opacity-100 transition-opacity preloadingScreenOpacityBack"></div>
        <div className="fixed inset-0 z-10 overflow-hidden overscroll-contain">
          <div className="px-8 md:px-12 py-3 md:py-8">
            <div className="flex h-screen items-center">
              <div className="m-auto flex preloadAnimationBox items-center">
                <h3 className="text-white text-4xl sm:text-5xl md:text-7xl preloadText1">
                  <img className='preloadimage' src={preload2} />
                </h3>
                <h3 className="text-white text-4xl sm:text-5xl md:text-7xl preloadText2 ml-4">
                  <img className='preloadimage' src={preload1} />
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default PreloadScreen