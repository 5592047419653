export const ADMIN_LOGIN="ADMIN_LOGIN"
export const ADMIN_VERIFY="ADMIN_VERIFY"
export const CAREER_DATA_UPLOAD="CAREER_DATA_UPLOAD"
export const CONTACT_DATA_UPLOAD="CONTACT_DATA_UPLOAD"
export const PROJECT_DATA_UPLOAD="PROJECT_DATA_UPLOAD"
export const FETCH_PROJECT_DATA="FETCH_PROJECT_DATA"
export const FETCH_CAREER_DATA="FETCH_CAREER_DATA"
export const ADD_NEW_TEAM_MEMBER="ADD_NEW_TEAM_MEMBER"
export const FETCH_TEAM_DATA="FETCH_TEAM_DATA"
export const EDIT_TEAM_MEMBER="EDIT_TEAM_MEMBER"
export const DELETE_TEAM_MEMBER="DELETE_TEAM_MEMBER"
export const DELETE_PROJECT="DELETE_PROJECT"
export const EDIT_PROJECT="EDIT_PROJECT"