import React from 'react'
import '../../../styles/Career/JoinUsSection.scss'
import joinus from '../../../assets/joinus.png'
function JoinUsSection() {
    return (
        <div className='px-4 sm:px-14 md:px-16 lg:px-40 mt-10'>
            <div className="grid grid-cols-12 py-10 lg:py-16 border-b border-primarygray">
            <div className='col-span-12 sm:col-span-12 lg:hidden  md:col-span-12 lg:col-span-7 xl:col-span-7'>
                <div className="flex justify-center">
                    <img src={joinus} alt="joinus" loading='lazy' className='joinUsSectionImg' />
                </div>
            </div>
            <div className='col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5 xl:col-span-5 mt-10 lg:mt-0'>
            <h2 className='text-secondary font-montserrat text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-semibold tracking-wide'>Join us.</h2>
            <p className="text-secondary mt-3 text-sm md:text-lg">
            At MJB, our culture of growth starts with our people. We look for members who are creative, hard-working, empathetic, and fun. We're not asking for our team members to be perfect. They just have to be “perfect” for MJB.

            </p>
            </div>
            <div className='col-span-12 sm:col-span-12 hidden lg:block  md:col-span-12 lg:col-span-7 xl:col-span-7'>
                <div className="flex justify-center">
                    <img src={joinus} alt="joinus" loading='lazy' className='joinUsSectionImg' />
                </div>
            </div>
            </div>
        </div>
    )
}

export default JoinUsSection