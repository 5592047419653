import React, { useCallback, useEffect } from 'react'
import '../../../../styles/AboutUs/OurTeamSection.scss'
function OurTeamSectionModal({ id,setShowModal, item }) {
    const escFunction = useCallback
    ((event) => {
        if (event.key === "Escape") {
          setShowModal(false)
        }
      }, [setShowModal]);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
    
        return () => {
          document.removeEventListener("keydown", escFunction, false);
        };
      }, [escFunction]);
    return (
        <>
                <div key={item.id} className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
    
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="flex justify-end">
                                <button onClick={()=>setShowModal(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                </div>
                              <div className="grid grid-cols-12 gap-2">
                                <div className="col-span-8">
                                <div>
                                    <img src={item.image} alt={item.fullName} className="rounded-md  shadow-xl ourTeamSectionModalImg" />
                                </div>
                                </div>
                                <div className="col-span-4 m-auto">
                                        <h3 className="font-montserrat text-xl sm:text-2xl text-secondary font-semibold ml-2">{item.name}</h3>
                                        <p className="font-montserrat text-sm sm:text-base ml-2">{item.designation}</p>
                                        <p className="font-montserrat text-sm sm:text-base ml-2">{item.subrole}</p>

                                </div>
                                <div className="col-span-12 mt-3">
                                    <p className="text-sm sm:text-base font-inter">{item.about}</p>
                                </div>
                              </div>
                                
                               
    
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurTeamSectionModal