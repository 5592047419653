import React, { useEffect, useState } from "react";
import ContactOptions from "./components/helper/ContactOptions";

import {
  LandingNavbar,
  HeroSection,
  ExperienceSection,
  GreatHomeSection,
  TrustedBySection,
  AboutUsSection,
  LandingFooter,
  PreloadScreen,
} from "./index";

function Landing() {
  const [preload, setPreload] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setPreload(false);
      document.body.style.overflow = "auto";
    }, 3500);
  }, []);
  return (
    <div className="min-h-screen bg-primary">
      {preload && <PreloadScreen />}
      {!preload && (
        <>
          {/* <ContactOptions bt="top-40" br="right-20" st="top-16" sr="right-5" /> */}
          <LandingNavbar val={true} />
          <HeroSection />
          <ExperienceSection />
          <GreatHomeSection />
          <TrustedBySection />
          <AboutUsSection />
          <LandingFooter back="bg-secondary" />
        </>
      )}
    </div>
  );
}

export default Landing;
