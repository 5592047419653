import axios from "axios";
import { FETCH_PROJECT_DATA, FETCH_TEAM_DATA } from "../constants/index";
import getAccessToken from "./cognito";
import { getS3Link, getSignedUrls } from "./aws";

import toast from "react-hot-toast";

export const contactUpload = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    getAccessToken()
      .then((tokens) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/form/contact-us`;
        axios
          .post(url, data, {
            headers: {
              Authorization: `Bearer ${tokens.AccessToken}`,
            },
          })
          .then((res) => resolve(res.data))
          .catch((err) => reject(err));
      })
      .catch((err) => {});
  });
};

export const careerUpload = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    getAccessToken()
      .then(async (tokens) => {
        const IdToken = tokens.IdToken;
        let cvLink = await getS3Link(data.cv, IdToken);
        let portfolioLink = await getS3Link(data.portfolio, IdToken);
        data.cv = cvLink;
        data.portfolio = portfolioLink;
        const url = `${process.env.REACT_APP_API_BASE_URL}/form/career`;
        axios
          .post(url, data, {
            headers: {
              Authorization: `Bearer ${tokens.AccessToken}`,
            },
          })
          .then((res) => resolve(res.data))
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProjectsData = () => async (dispatch) => {
  const projectUrl = `${process.env.REACT_APP_API_BASE_URL}/projects`;
  await axios
    .get(projectUrl)
    .then(async (res) => {
      const { data } = res;
      const images = [];
      data.map((item) => {
        images.push(
          `${process.env.REACT_APP_CLOUDFRONT_URL}/${item?.floorplan}`
        );
        item.gallery.map((galleryItem) =>
          images.push(`${process.env.REACT_APP_CLOUDFRONT_URL}/${galleryItem}`)
        );
      });
      const image_urls = await getSignedUrls(images);
      const result = [];
      data.map((item) => {
        const temp = { ...item };
        temp.floorplan =
          image_urls[
            `${process.env.REACT_APP_CLOUDFRONT_URL}/${item?.floorplan}`
          ];
        if (temp?.gallery !== undefined || temp?.gallery !== null) {
          const tempGallery = temp?.gallery;
          const gallerySigned = [];
          tempGallery.map((i) =>
            gallerySigned.push(
              image_urls[`${process.env.REACT_APP_CLOUDFRONT_URL}/${i}`]
            )
          );
          temp.gallery = gallerySigned;
        }
        temp.url = temp.gallery[0];
        result.push(temp);
      });
      dispatch({
        type: FETCH_PROJECT_DATA,
        payload: result,
      });
    })
    .catch((err) => {
      // toast.error(err.response.data.message);
    });
};

export const getTeamData = async () => {
  return new Promise(async (resolve, reject) => {
  const teamUrl = `${process.env.REACT_APP_API_BASE_URL}/team`;
  await axios
    .get(teamUrl)
    .then(async (res) => {
      const { data } = res;
      const images = [];
      data.map((item) => {
        images.push(
          `${process.env.REACT_APP_CLOUDFRONT_URL}/${item?.image}`
        );
      });
      const image_urls = await getSignedUrls(images);
      const result = [];
      data.map((item) => {
        const temp = { ...item };
        temp.image = image_urls[`${process.env.REACT_APP_CLOUDFRONT_URL}/${item?.image}`];
        result.push(temp);
        resolve(result)
      });
    })
    .catch((err) => {
      // toast.error(err.response.data.message);
      reject(err)
    });
  })
};

export const getTrustedByImages = async () => {
  return new Promise(async (resolve, reject) => {
    
      const slideShowImages = `${process.env.REACT_APP_API_BASE_URL}/assets/images`;
      axios
        .get(slideShowImages)
        .then(async (res) => {
          const { data } = res;
          const { trusted_by } = data
          
          const images = [];
          trusted_by.map((item) => {
            images.push(
              `${process.env.REACT_APP_CLOUDFRONT_URL}/${item}`
            );
          });
          const image_urls = await getSignedUrls(images);
          const result = [];
          trusted_by.map((item) => {
            result.push(
              image_urls[`${process.env.REACT_APP_CLOUDFRONT_URL}/${item}`]
            )
          });
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    })
};

export const getHeroSectionImages = async () => {
  return new Promise(async (resolve, reject) => {
    
      const slideShowImages = `${process.env.REACT_APP_API_BASE_URL}/assets/images`;
      axios
        .get(slideShowImages)
        .then(async (res) => {
          const { data } = res;
          const { hero_section } = data
          
          const images = [];
          hero_section.map((item) => {
            images.push(
              `${process.env.REACT_APP_CLOUDFRONT_URL}/${item}`
            );
          });
          const image_urls = await getSignedUrls(images);
          const result = [];
          hero_section.map((item) => {
            result.push(
              image_urls[`${process.env.REACT_APP_CLOUDFRONT_URL}/${item}`]
            )
          });
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    })
};

