import React, { useState, useEffect } from "react";
import "../../../styles/Landing/HeroSection.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getHeroSectionImages } from "../../../redux/actions";
import ScaleLoader from 'react-spinners/ScaleLoader'

function HeroSection() {
  const [heroSectionImages, setHeroSectionImages] = useState([]);

  useEffect(() => {
    getHeroSectionImages()
      .then((res) => setHeroSectionImages(res))
      .catch((err) => {});
  }, []);

  const getName = (url) => {
    return url;
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const heroData = heroSectionImages.map((tr) => (
    <div className="flex justify-center" key={tr}>
      <img src={tr} alt={getName(tr)} />
    </div>
  ));
  return (
     
    <div className="px-4 md:px-12">
      <div className="heroSectionContainer grid grid-cols-1">
        {/* <div className="col-span-1"> */}
        {heroSectionImages && heroSectionImages?.length ?
          <Carousel
            showDots={true}
            arrows={false}
            responsive={responsive}
            autoPlay={true}
            infinite={true}
            autoPlaySpeed={4000}
            transitionDuration={500}
          >
            {heroData}
          </Carousel>
          : (
            <>
                <div className="col-span-12 my-40">
                    <div className='flex justify-center items-center'>
                        <ScaleLoader size={100} color="black" />
      
                    </div>
                </div>
            </>
           )}
        {/* </div> */}
      </div>
      {/* <div className='text-center heroSectionHeaderBox'>
        <h2 className='text-8xl mt-auto font-montserrat font-semibold heroSectionHeader'>MJB <br />ARCHITECTS</h2>
        </div> */}
    </div>
  );
}

export default HeroSection;
